import "98.css";
import "./App.css";
// import { useState } from "react";

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <div style={{ width: 300 }} className='window'>
          <div className='title-bar'>
            <div className='title-bar-text'>Hi!</div>
            <div className='title-bar-controls'>
              <button aria-label='Minimize' />
              <button aria-label='Maximize' />
              <button aria-label='Close' />
            </div>
          </div>

          <div className='window-body'>
            <h3>Hi I'm Scott</h3>
            <p>I'm a software developer</p>
            <div className='field-row' style={{ justifyContent: "center" }}>
              {/* <button>
                <a href='mailto:scottfraserdev@gmail.com'>Email Me</a>
              </button> */}
              <button>
                <a href='https://www.linkedin.com/in/scott-a-fraser'>
                  LinkedIn
                </a>
              </button>
              <button>
                <a href='https://github.com/scottafraser'>GitHub</a>
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
